import i18next from 'i18next';
import { getErrorCode } from './appHelpers';

export const getImageUrl = (image, type = 'THUMBNAIL') => {
  if (!image || !image.types || !image.types.length) return null;
  const typeImage = image.types.find(i => i.type === type);
  return typeImage ? typeImage.url : image.types[0].url;
};

export const getMoviesCount = width => {
  let moviesCount = 6;
  if (width <= 1400) moviesCount = 5;
  if (width <= 1100) moviesCount = 4;
  if (width <= 800) moviesCount = 3;
  if (width <= 500) moviesCount = 2;
  return moviesCount;
};

export const getMoviesScrollSize = screenWidth => {
  const movieCount = getMoviesCount(screenWidth);
  const movieItemWidth = 300;
  const horizontalGaps = 2 * (screenWidth < 1024 ? 24 : 64);
  return movieCount * movieItemWidth + horizontalGaps;
};

export const getMoviesZoom = screenWidth => {
  const movieScrollSIze = getMoviesScrollSize(screenWidth);
  return screenWidth / movieScrollSIze;
};

export const getZoomStyle = zoom => {
  const isFireFox = false; //detectBrowser() === 'firefox';
  const zoomFactor = zoom;

  if (isFireFox) {
    return {
      width: '1920px',
      MozTransform: `scale(${zoomFactor})`,
      MozTransformOrigin: 'left top',
    };
  } else {
    return {
      zoom: zoomFactor,
    };
  }
};

export const getCurrentMedia = (movie, initialMediaId) => {
  if (!movie) return null;
  let seasonIndex = null;
  const lastEpisodeID = movie?.userData?.continue_watching_info?.last_seen_episode_id;
  const { category, seasons } = movie;
  const isShow = category === 'TV_SHOW';
  if (isShow) {
    const mediaId = initialMediaId || lastEpisodeID;
    const firstEpisode = seasons[0]?.episodes[0];
    firstEpisode.seasonIndex = 0;
    if (!mediaId) return firstEpisode;
    let episode;
    seasons.forEach((i, index) => {
      if (!episode) {
        episode = i.episodes.find(e => e.id === mediaId);
        if (episode) seasonIndex = index;
      }
    });
    const result = episode || firstEpisode;
    result.seasonIndex = seasonIndex;
    return result;
  } else {
    return movie.item;
  }
};

export const getCurrentSeasonEpisodeDesc = (movie, mediaId) => {
  if (!movie?.seasons) return '';
  const activeSeasonIndex = findActiveSeasonIndex(movie, mediaId);
  const activeEpisode = movie?.seasons?.[activeSeasonIndex]?.episodes?.findIndex(
    e => e.id === mediaId,
  );
  const seasonText = i18next.t('short_season');
  const epsiodeText = i18next.t('short_episode');
  return `${seasonText}${activeSeasonIndex + 1} ${epsiodeText}${activeEpisode + 1}`;
};

export const findActiveSeasonIndex = (movie, mediaId) => {
  const index = movie?.seasons?.findIndex(item => {
    return item.episodes.find(e => e.id === (mediaId || movie.last_seen_episode_id));
  });
  return index > 0 ? index : 0;
};

export const getNextEpisode = (movie, media) => {
  const { seasons } = movie;
  const mediaId = media.id;
  const activeSeasonIndex = findActiveSeasonIndex(movie, mediaId);
  const currentEpisodeIndex = seasons[activeSeasonIndex].episodes.findIndex(e => e.id === mediaId);
  const nextEpsiode = seasons[activeSeasonIndex].episodes[currentEpisodeIndex + 1];
  const nextSeason = seasons[activeSeasonIndex + 1];
  const result = nextEpsiode
    ? nextEpsiode
    : nextSeason
    ? nextSeason.episodes[0]
    : seasons[0].episodes[0];
  result.seasonIndex = nextEpsiode
    ? activeSeasonIndex
    : nextSeason.episodes[0]
    ? activeSeasonIndex + 1
    : 0;
  return result;
};

export const isMovieWatchFinished = movie => {
  const duration = movie.item ? movie.item.duration : movie.duration;
  const currentTime = movie.item ? movie.item.current_time : movie.current_time;
  return (duration - currentTime) / 1000 <= 30;
};

export const checkPaymentRentStatus = (onSuccess, onError) => {
  const url = new URL(window.location.href);
  const hasPaymentStatus = url.searchParams.get('status');
  const paymentMessage = url.searchParams.get('description');
  if (!hasPaymentStatus) return;
  if (hasPaymentStatus === 'success') {
    onSuccess(paymentMessage);
  }
  if (hasPaymentStatus === 'error') {
    onError(paymentMessage);
  }
};

export const checkPaySubscriptionStatus = (onSuccess, onError) => {
  const url = new URL(window.location.href);
  const hasPaymentStatus = url.searchParams.get('status');
  const paymentMessage = url.searchParams.get('description');
  if (!hasPaymentStatus) return;
  if (hasPaymentStatus === 'success') {
    onSuccess(paymentMessage);
  }
  if (hasPaymentStatus === 'error') {
    onError(paymentMessage);
  }
};

export const isUserSubscribed = (paymentInfo, monetization, userPlans) => {
  if (paymentInfo.isSvod && monetization?.svod?.length) {
    const planID = monetization.svod[0].id;
    return !!userPlans[planID];
  }

  return false;
};

export const isCurrentMediaPlayable = (currentMovie, mediaId, userInfo) => {
  const isTvShow = currentMovie.category === 'TV_SHOW';
  if (isTvShow) {
    const epsiode = getCurrentMedia(currentMovie, mediaId);
    const season = currentMovie.seasons[epsiode.seasonIndex];
    return isEpisodePlayable(epsiode, season, currentMovie, userInfo);
  } else {
    return isMoviePlayable(currentMovie, userInfo);
  }
};

export const isMoviePlayable = (movie, userInfo) => {
  if (movie?.paymentInfo?.isFree) return true;
  const { isSvod, isTvod, isFvod, isAvod } = movie?.paymentInfo;
  const isPaid = movie?.userData?.is_purchased;
  const isSubscribed = isUserSubscribed(movie?.paymentInfo, movie?.monetization, userInfo.plans);
  return (isTvod && isPaid) || (isSvod && isSubscribed) || isFvod || isAvod;
};

export const isEpisodePlayable = (episode, season, movie, userInfo) => {
  const mainPaymentInfo = episode.paymentInfo || season.paymentInfo || movie.paymentInfo;
  const mainMonetization = episode.monetization || season.monetization || movie.monetization;
  if (mainPaymentInfo.isFree) return true;
  const { isSvod, isTvod, isFvod, isAvod } = mainPaymentInfo;
  const isPaid = movie?.userData?.is_purchased;
  const isSubscribed = isUserSubscribed(mainPaymentInfo, mainMonetization, userInfo.plans);
  return (isTvod && isPaid) || (isSvod && isSubscribed) || isFvod || isAvod;
};

export const hideAds = (movie, userInfo) => {
  const { isSvod, isTvod, isFvod, isAvod } = movie?.paymentInfo;
  if (movie?.paymentInfo?.isFree && !isAvod) return true;
  const isPaid = movie?.userData?.is_purchased;
  const isSubscribed = isUserSubscribed(movie?.paymentInfo, movie?.monetization, userInfo.plans);
  return (isTvod && isPaid) || (isSvod && isSubscribed) || isFvod;
};

export const movieErrorRedirect = (err, history) => {
  const error = getErrorCode(err);
  if (error?.status === 403) {
    history.push('/blocked');
    return;
  }
  if (error?.status === 404) {
    history.push('/404');
    return;
  }
  history.push('/home');
};
