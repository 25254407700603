/* eslint-disable prettier/prettier */
import React from 'react';
import store from 'redux/store';
import i18next from 'i18next';
import { Api } from 'utils/connectors';
import { onSetUserLocation, onSetUserPlans, onSetUserVotes } from './userInfo';
import { getLang } from 'utils/appHelpers';
import { isUserSubscribed } from 'utils/movieHelper';
import { IconCheckmark } from 'shared/components/IconsV2';
import { confirmBox } from 'utils/modalHelper';

export const showMTSSuccess = (alert, newIds, plans) => {
  if (!newIds?.length) return;
  const plan = plans.find(i => i.subscription_plan_id === newIds[0]);
  const options = {
    icon: <IconCheckmark width={36} height={36} />,
    title: alert?.title || i18next.t('title_congratulations'),
    message: alert?.message || i18next.t('mts_success_message', { plan: plan.subscription_plan_name }),
    confirm: i18next.t('btn_start_watching'),
  };
  confirmBox(options);
};

export const getUserInfos = async () => {
  try {
    const votes = await Api.get('/medias/vote');
    const userPlans = await Api.get('/subscription-plans/my', {
      params: { locale: getLang() },
      headers: {
        Accept: 'application/v2+json',
      },
    });
    showMTSSuccess(userPlans?.data?.alert, userPlans?.data?.new_subscription_ids, userPlans.data.active_subscriptions);
    const settings = await Api.get('/settings?configs=location_detail');
    store.dispatch(onSetUserLocation(settings.data?.location_detail));
    store.dispatch(onSetUserPlans(userPlans.data.active_subscriptions));
    store.dispatch(onSetUserVotes(votes.data));
  } catch (err) {
    throw new Error(err);
  }
};

// TODO: check monetization
export const checkIsUserSubscribed = async movie => {
  const userInfo = store.getState().userInfo;
  return isUserSubscribed(movie?.paymentInfo, movie?.monetization, userInfo.plans);
};
